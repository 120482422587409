.dealmaker > td {
  font-weight: bold !important;
  font-size: 1.25rem !important;
}

.col > h1, h2, h3, h4{
  font-weight: bold;
}

.number-column {
  text-align: right;
}

.arrow-up{
  color:greenyellow;
  position: relative;
  display: inline;
  animation: moveup 1s infinite;
}
.arrow-down{
  color: red;
  position: relative;
  display: inline;
  animation: movedown 1s infinite;
}

@keyframes moveup{
  from {top: 5px;}
  to {top: -5px;}
}

@keyframes movedown{
  from {top: -5px;}
  to {top: 5px;}
}